import React, { useEffect, useRef, useState } from 'react'
import * as S from './styles'

import { useTranslation } from 'react-i18next'

const NewModuleTag = ({ expDate }) => {
  const { t } = useTranslation()

  const [show, setShow] = useState(true)
  const [labelsNewModule, setLabelsNewModule] = useState('')

  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) return

    const now = new Date()
    const exp = new Date(expDate)

    setShow(now <= exp)
    didMount.current = true
  }, [])

  useEffect(() => {
    setLabelsNewModule(t('labels.newModule'))
  }, [t])

  return (
    show && (
      <S.NewModuleTagContainer>
        <span>{labelsNewModule}</span>
      </S.NewModuleTagContainer>
    )
  )
}

export default NewModuleTag
