import styled from 'styled-components'

export const FadeCarouselWrapper = styled.div`
  position: relative;
`

export const FadeCarouselItem = styled.div`
  transition: opacity ${props => props.speed}ms;
  opacity: 0;
  z-index: 0;

  &.selected {
    opacity: 1;
    z-index: 1;
  }
`

export const FadeCarouselDotContainer = styled.div`
  position: relative;
  width: fit-content;
  padding: 0.25rem;
  left: 70%;
  transform: translateX(-50%);

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  @media only screen and (max-width: 700px) {
    left: 50%;
  }
`

export const FadeCarouselDot = styled.button`
  background: var(--light);
  width: 12px;
  height: 12px;
  border: 2px solid var(--orange2);
  border-radius: 50%;

  &.selected {
    background: var(--orange2);
    pointer-events: none;
  }
`
