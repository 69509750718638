import styled from 'styled-components'

export const DecorationContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  left: ${props => props.posX};
  top: ${props => props.posY};
  z-index: 0;
`

export const DecorationCircle = styled.div`
  background: ${props => props.color};
  width: ${props => props.radius};
  height: ${props => props.radius};
  border-radius: 50%;
`

export const DecorationImg = styled.img`
  filter: ${props => props.filter};
  opacity: ${props => props.opacity};
  width: ${props => props.width};
  height: ${props => props.height};
  transform: rotate(${props => props.rotation});
`
