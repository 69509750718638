import styled from 'styled-components'

export const AvailabilityContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  span:not(.icon) {
    margin-right: 1.5rem;
    font-size: 16px;
  }
`

export const Platform = styled.div`
  display: flex;

  span.icon {
    margin-left: 0.25rem;
    margin-right: 1rem;
    font-size: 12px;
    color: var(---dark);
  }
`
