import styled from 'styled-components'

export const BannerItemContainer = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;

  & > * {
    width: 50%;
  }

  @media only screen and (max-width: 700px) {
    display: block;

    & > * {
      width: 100%;
    }
  }
`

export const BannerTextContainer = styled.div`
  h2 {
    font-size: 46px;
    font-weight: 800;
    line-height: 56px;
    color: var(--blue11);
    margin-bottom: 2rem;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    color: var(--grey20);
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 1050px) {
    h2 {
      font-size: 32px;
      line-height: 39px;
    }

    p {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 700px) {
    text-align: center;

    h2 {
      font-size: 24px;
      line-height: 29px;
    }

    a,
    button {
      max-width: 100% !important;
      width: 100%;
      margin-bottom: 3rem;
    }
  }
`

export const BannerImgContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  img {
    position: relative;
    margin: 0 auto;
    z-index: 1;

    &:not(.decorative) {
      max-width: 100%;
    }

    @media only screen and (max-width: 1050px) {
      &:not(.decorative) {
        width: 100%;
      }
    }
  }
`
