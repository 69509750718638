import styled from 'styled-components'

export const NewModuleTagContainer = styled.div`
  background: rgba(10, 155, 92, 0.15);
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  padding: 0.5rem;

  span {
    font-weight: 600;
    color: var(--green);
  }
`
