import styled from 'styled-components'

export const BackToTopButtonWrapper = styled.div`
  position: relative;
  max-width: ;
  margin: 0 auto;
`

export const BackToTopButtonContainer = styled.button`
  position: fixed;
  right: calc((100vw - var(--unsignedMaxWidth)) / 2);
  top: 88%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light);
  border: 1px solid var(--grey2);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  z-index: 20;

  span {
    font-size: 40px;
    color: var(--grey2);
  }

  @media only screen and (max-width: 1366px) {
    right: 2rem;
  }

  @media only screen and (max-width: 500px) {
    width: 35px;
    height: 35px;

    span {
      font-size: 30px;
    }
  }
`
