import React, { useEffect, useState } from 'react'
import * as S from './styles'

import { useWindowSize } from '@/contexts/WindowSizeContext'

const Decoration = ({ data }) => {
  const { windowSize } = useWindowSize()

  const [width, setWidth] = useState()
  const [height, setHeight] = useState()
  const [posX, setPosX] = useState()
  const [posY, setPosY] = useState()

  useEffect(() => {
    if (!data || !windowSize.width) return

    if (data.responsive?.length > 0) {
      const breakpoint = data.responsive.find(
        pt => windowSize.width < pt.breakpoint
      )

      if (breakpoint) {
        setResponsiveDimensions(breakpoint)
      } else {
        setDefaultDimensions()
      }
    } else {
      setDefaultDimensions()
    }
  }, [data, windowSize])

  const setDefaultDimensions = () => {
    setWidth(data.width)
    setPosX(data.pos.x)
    setPosY(data.pos.y)

    if (data.height) {
      setHeight(data.height)
    }
  }

  const setResponsiveDimensions = breakpointData => {
    const ptWidth = breakpointData.width ? breakpointData.width : data.width
    const ptPosX = breakpointData.pos ? breakpointData.pos.x : data.pos.x
    const ptPosY = breakpointData.pos ? breakpointData.pos.y : data.pos.y

    setWidth(ptWidth)
    setPosX(ptPosX)
    setPosY(ptPosY)

    if (breakpointData.height) {
      setHeight(breakpointData.height)
    } else if (data.height) {
      setHeight(data.height)
    }
  }

  return (
    data && (
      <S.DecorationContainer posX={posX} posY={posY}>
        {data.type === 'circle' ? (
          <S.DecorationCircle
            color={data.color}
            radius={width}
          ></S.DecorationCircle>
        ) : (
          <S.DecorationImg
            src={data.type}
            alt=""
            className="decorative"
            filter={data.color}
            opacity={data.opacity}
            rotation={data.rotation ? data.rotation : 0}
            width={width}
            height={height}
          />
        )}
      </S.DecorationContainer>
    )
  )
}

export default Decoration
