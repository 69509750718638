import React, { useEffect, useState } from 'react'
import * as S from './styles'

import ImageNext from '@/components/ImageNext'

import { useTranslation } from 'react-i18next'

const baseIconURL = `${process.env.CDN_URL}/public/icons/`

const Availability = ({ platforms, className }) => {
  const { t } = useTranslation()

  const [labelsAvailability, setLabelsAvailability] = useState('')

  useEffect(() => {
    setLabelsAvailability(t('labels.availability'))
  }, [t])

  const renderPlatform = (platform, index) => {
    return (
      <S.Platform key={index}>
        <ImageNext
          src={`${baseIconURL}${platform.toLowerCase()}.svg`}
          alt=""
          width={16}
          height={16}
          autoSize={false}
        />
        <span className="icon">{platform}</span>
      </S.Platform>
    )
  }

  return (
    platforms && (
      <S.AvailabilityContainer className={className}>
        <span>{labelsAvailability}</span>
        {platforms.map((plat, i) => renderPlatform(plat, i))}
      </S.AvailabilityContainer>
    )
  )
}

export default Availability
