import React, { useEffect, useState } from 'react'
import * as S from './styles'

import ImageNext from '@/components/ImageNext'
import Decoration from '../../Decoration'

import { useTranslation } from 'react-i18next'

const BannerItem = ({ data, index, videoHandler }) => {
  const { t } = useTranslation()

  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const [buttonText, setButtonText] = useState('')

  useEffect(() => {
    if (!data) return

    setTitle(t(data.title))
    setText(t(data.text))
    setButtonText(t(data.buttonText))
  }, [t, data])

  return (
    <S.BannerItemContainer className="mw-unsigned">
      <S.BannerTextContainer>
        <h2>{title}</h2>
        <p>{text}</p>
        <a
          href={data.linkRoute}
          onClick={data.buttonCallback && data.buttonCallback}
          id={`btn_banner_${index}`}
          className="btn lg orange round"
        >
          {buttonText}
        </a>
      </S.BannerTextContainer>

      <S.BannerImgContainer>
        {data.decoration?.map((decor, i) => (
          <Decoration key={i} data={decor} />
        ))}
        {data.modal ? (
          <ImageNext
            src={data.image}
            onClick={() => videoHandler(data.modal)}
          />
        ) : (
          <ImageNext src={data.image} />
        )}
      </S.BannerImgContainer>
    </S.BannerItemContainer>
  )
}

export default BannerItem
