import React, { useEffect, useState } from 'react'
import * as S from './styles'

import BannerItem from './BannerItem'
import FadeCarousel from '@/components/FadeCarousel'

import { useModal } from '@/contexts/ModalContext'

const BannerSection = ({ data }) => {
  const { MODAL_TYPES, callModal, closeModal } = useModal()

  const [videoSrc, setVideoSrc] = useState('')

  useEffect(() => {
    if (!videoSrc) closeModal()
    else
      callModal({
        type: MODAL_TYPES.banner_video,
        config: { videoSrc: videoSrc, videoHandler: setVideoSrc },
        classes: 'with-background'
      })
  }, [videoSrc])

  const carouselSettings = {
    autoplay: true,
    autoplaySpeed: 10000
  }

  const renderSingleItem = itemData => {
    return <BannerItem data={itemData} index={0} videoHandler={setVideoSrc} />
  }

  const renderMultipleItems = () => {
    return (
      <FadeCarousel {...carouselSettings}>
        {data.map((item, i) => {
          return (
            <BannerItem
              key={i}
              data={item}
              index={i}
              videoHandler={setVideoSrc}
            />
          )
        })}
      </FadeCarousel>
    )
  }

  return (
    data && (
      <>
        <S.BannerContainer>
          {data.length > 1 ? renderMultipleItems() : renderSingleItem(data[0])}
        </S.BannerContainer>
      </>
    )
  )
}

export default BannerSection
