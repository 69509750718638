import React, { useEffect, useState, useRef } from 'react'
import * as S from './styles'

import { isValueDefined } from '@/services/functions'

const FadeCarousel = props => {
  const {
    children,
    defaultIndex = 0,
    autoplay = false,
    autoplaySpeed = 3000,
    speed = 250,
    infinite = true,
    dots = true,
    pauseOnHover = true,
    pauseOnDotsHover = true,
    wrapperClass,
    itemClass,
    dotContainerClass,
    dotClass,
    onChange
  } = props

  const [currentIndex, setCurrentIndex] = useState()

  const didInit = useRef(false)
  const isPaused = useRef(false)
  const currentIndexRef = useRef()
  const startTimestamp = useRef()
  const remainingTime = useRef(autoplaySpeed)
  const timeout = useRef(null)

  useEffect(() => {
    if (!children || didInit.current) return

    didInit.current = true
    initCarousel()
  }, [children])

  useEffect(() => {
    return () => {
      stopAutoplay()
    }
  }, [])

  useEffect(() => {
    if (!isValueDefined(currentIndex)) return

    currentIndexRef.current = currentIndex
    applySelectVisualItem(currentIndex)
  }, [currentIndex])

  const initCarousel = () => {
    const index = defaultIndex ? defaultIndex : 0
    manualSelect(index)

    if (autoplay) {
      setAutoplay(autoplaySpeed)
    }
  }

  const applySelectVisualItem = index => {
    const newItem = document.getElementById(`carousel_item_${index}`)
    newItem.classList.add('selected')
  }

  const applySelectVisualDot = index => {
    const newDot = document.getElementById(`carousel_dot_${index}`)
    newDot.classList.add('selected')
  }

  const applyDeselectVisual = index => {
    const oldItem = document.getElementById(`carousel_item_${index}`)
    const oldDot = document.getElementById(`carousel_dot_${index}`)

    oldItem.classList.remove('selected')
    oldDot.classList.remove('selected')
  }

  const manualSelect = index => {
    if (currentIndexRef.current === index) return

    if (isValueDefined(currentIndexRef.current)) {
      applyDeselectVisual(currentIndexRef.current)
      setTimeout(() => setCurrentIndex(index), speed)
    } else {
      setCurrentIndex(index)
    }

    applySelectVisualDot(index)
    onChange && onChange()
  }

  const autoSelect = () => {
    timeout.current = null
    let nextIndex
    if (currentIndexRef.current === children.length - 1) {
      if (infinite) nextIndex = 0
      else return
    } else {
      nextIndex = currentIndexRef.current + 1
    }

    startTimestamp.current = Date.now()
    manualSelect(nextIndex)
    setAutoplay(autoplaySpeed)
  }

  const buttonSelect = index => {
    manualSelect(index)

    if (autoplay) {
      remainingTime.current = autoplaySpeed
      if (!isPaused.current) {
        stopAutoplay()
        setAutoplay(autoplaySpeed)
      }
    }
  }

  const setAutoplay = time => {
    if (timeout.current !== null) {
      console.warn(
        'FadeCarousel error: tried to create overlapping timeouts!',
        timeout.current
      )
      return
    }

    timeout.current = setTimeout(autoSelect, time)
  }

  const pauseAutoplay = () => {
    if (isPaused.current) return

    stopAutoplay()
    remainingTime.current = getRemainingTime()
    isPaused.current = true
  }

  const resumeAutoplay = () => {
    if (!isPaused.current) return

    setAutoplay(remainingTime.current)
    remainingTime.current = autoplaySpeed
    isPaused.current = false
  }

  const stopAutoplay = () => {
    clearTimeout(timeout.current)
    timeout.current = null
  }

  const getRemainingTime = () => {
    const elapsedTime = Date.now() - startTimestamp.current
    return autoplaySpeed - elapsedTime
  }

  return (
    <S.FadeCarouselWrapper
      className={wrapperClass}
      onMouseEnter={() => {
        if (pauseOnHover) pauseAutoplay()
      }}
      onMouseLeave={() => {
        if (pauseOnHover) resumeAutoplay()
      }}
    >
      <div id="carousel_item_container">
        {currentIndex !== undefined && currentIndex !== null && (
          <S.FadeCarouselItem
            id={`carousel_item_${currentIndex}`}
            className={itemClass}
            speed={speed}
          >
            {children[currentIndex]}
          </S.FadeCarouselItem>
        )}
      </div>

      {dots && (
        <S.FadeCarouselDotContainer
          className={dotContainerClass}
          onMouseEnter={() => {
            if (pauseOnDotsHover) pauseAutoplay()
          }}
          onMouseLeave={() => {
            if (pauseOnDotsHover) resumeAutoplay()
          }}
        >
          {children.map((item, i) => (
            <S.FadeCarouselDot
              key={i}
              id={`carousel_dot_${i}`}
              className={dotClass}
              onClick={() => buttonSelect(i)}
            ></S.FadeCarouselDot>
          ))}
        </S.FadeCarouselDotContainer>
      )}
    </S.FadeCarouselWrapper>
  )
}

export default FadeCarousel
