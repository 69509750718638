import React, { useEffect, useState } from 'react'
import * as S from './styles'

import { useTranslation } from 'react-i18next'

const Newsletter = () => {
  const { t } = useTranslation()

  const [subtitlesNewsletter, setSubtitlesNewsletter] = useState('')
  const [labelsEmail, setLabelsEmail] = useState('')

  const formID = '_form_71'

  useEffect(() => {
    setSubtitlesNewsletter(t('subtitles.newsletter'))
    setLabelsEmail(t('labels.email'))
  }, [t])

  useEffect(() => {
    if (!formID) return

    window._show_thank_you = function (id, message, trackcmp_url, email) {
      var form = document.getElementById(formID),
        thank_you = form.querySelector('._form-thank-you')
      form.querySelector('._form-content').style.display = 'none'
      thank_you.innerHTML = message
      thank_you.style.display = 'block'
      const vgoAlias =
        typeof visitorGlobalObjectAlias === 'undefined'
          ? 'vgo'
          : visitorGlobalObjectAlias
      var visitorObject = window[vgoAlias]
      if (email && typeof visitorObject !== 'undefined') {
        visitorObject('setEmail', email)
        visitorObject('update')
      } else if (typeof trackcmp_url != 'undefined' && trackcmp_url) {
        // Site tracking URL to use after inline form submission.
        _load_script(trackcmp_url)
      }
      if (typeof window._form_callback !== 'undefined')
        window._form_callback(id)
    }

    window._show_error = function (id, message, html) {
      var form = document.getElementById('_form_' + id + '_'),
        err = document.createElement('div'),
        button = form.querySelector('button'),
        old_error = form.querySelector('._form_error')
      if (old_error) old_error.parentNode.removeChild(old_error)
      err.innerHTML = message
      err.className = '_error-inner _form_error _no_arrow'
      var wrapper = document.createElement('div')
      wrapper.className = '_form-inner'
      wrapper.appendChild(err)
      button.parentNode.insertBefore(wrapper, button)
      document.querySelector('[id^="_form"][id$="_submit"]').disabled = false
      if (html) {
        var div = document.createElement('div')
        div.className = '_error-html'
        div.innerHTML = html
        err.appendChild(div)
      }
    }

    window._load_script = function (url, callback) {
      var head = document.querySelector('head'),
        script = document.createElement('script'),
        r = false
      script.type = 'text/javascript'
      script.charSet = 'utf-8'
      script.src = url
      if (callback) {
        script.onload = script.onreadystatechange = function () {
          if (!r && (!this.readyState || this.readyState == 'complete')) {
            r = true
            callback()
          }
        }
      }
      head.appendChild(script)
    }

    function initForm() {
      if (window.location.search.search('excludeform') !== -1) return false

      var getCookie = function (name) {
        var match = document.cookie.match(
          new RegExp('(^|; )' + name + '=([^;]+)')
        )
        return match ? match[2] : null
      }

      var setCookie = function (name, value) {
        var now = new Date()
        var time = now.getTime()
        var expireTime = time + 1000 * 60 * 60 * 24 * 365
        now.setTime(expireTime)
        document.cookie = name + '=' + value + '; expires=' + now + ';path=/'
      }

      var addEvent = function (element, event, func) {
        if (element.addEventListener) {
          element.addEventListener(event, func)
        } else {
          var oldFunc = element['on' + event]
          element['on' + event] = function () {
            oldFunc.apply(this, arguments)
            func.apply(this, arguments)
          }
        }
      }

      var getUrlParam = function (name) {
        var params = new URLSearchParams(window.location.search)
        return params.get(name) || false
      }

      var remove_tooltips = function () {
        for (var i = 0; i < tooltips.length; i++) {
          tooltips[i].tip.parentNode.removeChild(tooltips[i].tip)
        }
        tooltips = []
      }

      var remove_tooltip = function (elem) {
        for (var i = 0; i < tooltips.length; i++) {
          if (tooltips[i].elem === elem) {
            tooltips[i].tip.parentNode.removeChild(tooltips[i].tip)
            tooltips.splice(i, 1)
            return
          }
        }
      }

      var create_tooltip = function (elem, text) {
        var tooltip = document.createElement('div'),
          arrow = document.createElement('div'),
          inner = document.createElement('div'),
          new_tooltip = {}
        if (elem.type != 'radio' && elem.type != 'checkbox') {
          tooltip.className = '_error'
          arrow.className = '_error-arrow'
          inner.className = '_error-inner'
          inner.innerHTML = text
          tooltip.appendChild(arrow)
          tooltip.appendChild(inner)
          elem.parentNode.appendChild(tooltip)
        } else {
          tooltip.className = '_error-inner _no_arrow'
          tooltip.innerHTML = text
          elem.parentNode.insertBefore(tooltip, elem)
          new_tooltip.no_arrow = true
        }
        new_tooltip.tip = tooltip
        new_tooltip.elem = elem
        tooltips.push(new_tooltip)
        return new_tooltip
      }

      var resize_tooltip = function (tooltip) {
        var rect = tooltip.elem.getBoundingClientRect()
        var doc = document.documentElement,
          scrollPosition =
            rect.top -
            ((window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0))
        if (scrollPosition < 40) {
          tooltip.tip.className =
            tooltip.tip.className.replace(/ ?(_above|_below) ?/g, '') +
            ' _below'
        } else {
          tooltip.tip.className =
            tooltip.tip.className.replace(/ ?(_above|_below) ?/g, '') +
            ' _above'
        }
      }

      var resize_tooltips = function () {
        if (_removed) return
        for (var i = 0; i < tooltips.length; i++) {
          if (!tooltips[i].no_arrow) resize_tooltip(tooltips[i])
        }
      }

      var validate_field = function (elem, remove) {
        var tooltip = null,
          value = elem.value,
          no_error = true
        remove ? remove_tooltip(elem) : false
        if (elem.getAttribute('required') !== null) {
          if (value === undefined || value === null || value === '') {
            elem.className = elem.className + ' _has_error'
            no_error = false
            tooltip = create_tooltip(elem, 'Este campo é necessário.')
          }
        }
        if (no_error && elem.name == 'email') {
          if (
            !value.match(
              /^[\+_a-z0-9-'&=]+(\.[\+_a-z0-9-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i
            )
          ) {
            elem.className = elem.className + ' _has_error'
            no_error = false
            tooltip = create_tooltip(elem, 'Digite um e-mail válido')
          }
        }
        tooltip ? resize_tooltip(tooltip) : false
        return no_error
      }

      var needs_validate = function (el) {
        if (el.getAttribute('required') !== null) {
          return true
        }
        if (el.name === 'email' && el.value !== '') {
          return true
        }
        return false
      }

      var validate_form = function (e) {
        var err = form_to_submit.querySelector('._form_error'),
          no_error = true
        if (!submitted) {
          submitted = true
          for (var i = 0, len = allInputs.length; i < len; i++) {
            var input = allInputs[i]
            if (needs_validate(input)) {
              if (
                input.type == 'text' ||
                input.type == 'number' ||
                input.type == 'time'
              ) {
                addEvent(input, 'blur', function () {
                  this.value = this.value.trim()
                  validate_field(this, true)
                })
                addEvent(input, 'input', function () {
                  validate_field(this, true)
                })
              }
            }
          }
        }
        remove_tooltips()
        for (var i = 0, len = allInputs.length; i < len; i++) {
          var elem = allInputs[i]
          if (needs_validate(elem)) {
            if (elem.tagName.toLowerCase() !== 'select') {
              elem.value = elem.value.trim()
            }
            validate_field(elem) ? true : (no_error = false)
          }
        }
        if (!no_error && e) {
          e.preventDefault()
        }
        resize_tooltips()
        return no_error
      }

      var _form_serialize = function (form) {
        if (!form || form.nodeName !== 'FORM') {
          return
        }
        var i,
          j,
          q = []
        for (i = 0; i < form.elements.length; i++) {
          if (form.elements[i].name === '') {
            continue
          }
          switch (form.elements[i].nodeName) {
            case 'INPUT':
              q.push(
                form.elements[i].name +
                  '=' +
                  encodeURIComponent(form.elements[i].value)
              )
              break
            case 'BUTTON':
              switch (form.elements[i].type) {
                case 'reset':
                case 'submit':
                case 'button':
                  q.push(
                    form.elements[i].name +
                      '=' +
                      encodeURIComponent(form.elements[i].value)
                  )
                  break
              }
              break
          }
        }
        return q.join('&')
      }

      var form_submit = function (e) {
        e.preventDefault()
        if (validate_form()) {
          // use this trick to get the submit button & disable it using plain javascript
          document.querySelector(`#${formID}_submit`).disabled = true
          var serialized = _form_serialize(
            document.getElementById(formID)
          ).replace(/%0A/g, '\\n')
          var err = form_to_submit.querySelector('._form_error')
          err ? err.parentNode.removeChild(err) : false
          _load_script(
            'https://medicalharbour86050.activehosted.com/proc.php?' +
              serialized +
              '&jsonp=true'
          )
        }
        return false
      }

      var _removed = false
      var form_to_submit = document.getElementById(formID)
      var allInputs = form_to_submit.querySelectorAll(
          'input'
        ),
        tooltips = [],
        submitted = false

      for (var i = 0; i < allInputs.length; i++) {
        var regexStr = 'field\\[(\\d+)\\]'
        var results = new RegExp(regexStr).exec(allInputs[i].name)
        if (results != undefined) {
          allInputs[i].dataset.name = window.cfields[results[1]]
        } else {
          allInputs[i].dataset.name = allInputs[i].name
        }
        var fieldVal = getUrlParam(allInputs[i].dataset.name)

        if (fieldVal) {
          if (allInputs[i].dataset.autofill === 'false') {
            continue
          }
          allInputs[i].value = fieldVal
        }
      }

      addEvent(window, 'resize', resize_tooltips)
      addEvent(window, 'scroll', resize_tooltips)
      addEvent(form_to_submit, 'submit', form_submit)
    }
    initForm()
  }, [formID])

  const renderActiveCampaignFormPT = () => {
    return (
      <div className="form-container">
        <form
          method="POST"
          action="https://medicalharbour86050.activehosted.com/proc.php"
          id={formID}
          className="_form _form_71 _inline-form _inline-style _dark active"
          noValidate
        >
          <input type="hidden" name="u" value="71" />
          <input type="hidden" name="f" value="71" />
          <input type="hidden" name="s" />
          <input type="hidden" name="c" value="0" />
          <input type="hidden" name="m" value="0" />
          <input type="hidden" name="act" value="sub" />
          <input type="hidden" name="v" value="2" />
          <input
            type="hidden"
            name="or"
            value="5729260e68c293ec09aca2772591aa1d"
          />
          <div className="_form-content">
            <div className="_form_element _field-email _inline-style ">
              <label htmlFor="email" className="_form-label" style={{ display: 'none' }}>
                {`${labelsEmail}*`}
              </label>
              <div className="_field-wrapper">
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder='E-mail'
                  required
                />
              </div>
              {/* <!--  This STARTS the Account Custom Fields section  --> */}
            </div>
            <div className="_button-wrapper _inline-style">
              <button
                id="_form_71_submit"
                className="btn blue md"
                type="submit"
              >
                OK
              </button>
            </div>
            <div className="_clear-element"></div>
          </div>
          <div className="_form-thank-you" style={{ display: 'none' }}></div>
        </form>
      </div>
    )
  }

  const hideForm = () => {
    if (!formID) return
    document.getElementById(formID).parentElement.classList.remove('active')
  }

  return (
    <S.NewsletterWrapper>
      <S.NewsletterContainer className="mw-unsigned">
        <h2>Newsletter</h2>
        <p>{subtitlesNewsletter}</p>
        <S.NewsletterForm>
          {/* 
          <div class="_form_71"></div><script src="https://medicalharbour86050.activehosted.com/f/embed.php?id=71" type="text/javascript" charset="utf-8"></script>

          */}
          {/* <input
            type="email"
            placeholder={placeholdersEmail}
            className="round"
          />
          <button
            id="btn_newsletter"
            type="submit"
            className="btn lg orange round"
          >
            {buttonsGetNews}
          </button> */}
          {renderActiveCampaignFormPT()}
        </S.NewsletterForm>
      </S.NewsletterContainer>
    </S.NewsletterWrapper>
  )
}

export default Newsletter
