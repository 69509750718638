import React, { useEffect, useRef, useState } from 'react'
import * as S from './styles'

import { EVENT_KEYS } from '@/services/events'

const BackToTopButton = ({ threshold }) => {
  const [showButton, setShowButton] = useState(false)

  const showButtonRef = useRef()
  const didLoad = useRef(false)

  useEffect(() => {
    if (didLoad.current) return

    didLoad.current = true
    if (threshold) {
      const listener = document.addEventListener(EVENT_KEYS.scroll, e => {
        if (!showButtonRef.current && window.scrollY >= threshold) {
          setShowButton(true)
        } else if (showButtonRef.current && window.scrollY < threshold) {
          setShowButton(false)
        }
      })

      return () => {
        document.removeEventListener(EVENT_KEYS.scroll, listener)
      }
    } else {
      setShowButton(true)
    }
  }, [])

  useEffect(() => {
    showButtonRef.current = showButton
  }, [showButton])

  const goToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    showButton && (
      <S.BackToTopButtonWrapper>
        <S.BackToTopButtonContainer
          type="button"
          id="btn_back_to_top"
          onClick={goToTop}
        >
          <span className="material-icons-round">arrow_upward</span>
        </S.BackToTopButtonContainer>
      </S.BackToTopButtonWrapper>
    )
  )
}

export default BackToTopButton
