import React from 'react'
import Head from 'next/head'

const SEO = props => {
  const { title, description, image } = props

  return (
    <Head>
      <title>{`${process.env.APP_NAME}${title ? title : ''}`}</title>
      <meta
        itemProp="name"
        content={`${process.env.APP_NAME}${title ? title : ''}`}
      />
      {description && <meta name="description" content={description} />}
      {description && <meta itemProp="description" content={description} />}
      {image && <meta itemProp="image" content={image} />}
    </Head>
  )
}

export default SEO
