import styled from 'styled-components'

export const NewsletterWrapper = styled.div`
  background: conic-gradient(
    at center 84%,
    var(--blue10) 0deg 86deg,
    var(--blue6) 86deg 274deg,
    var(--blue10) 274deg 0deg
  );
`

export const NewsletterContainer = styled.div`
  margin: 0 auto;
  padding: 4rem 6rem;
  text-align: center;

  h2 {
    font-size: 20px;
    color: var(--grey6);
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 36px;
    font-weight: 700;
    line-height: 46px;
    color: var(--light);
    margin-bottom: 4rem;
  }

  @media only screen and (max-width: 1050px) {
    p {
      font-size: 32px;
      line-height: 39px;
    }
  }

  @media only screen and (max-width: 700px) {
    h2 {
      font-size: 14px;
    }

    p {
      font-size: 24px;
      line-height: 29px;
    }
  }
`
export const NewsletterForm = styled.div`
  button {
    margin-left: 1rem;
    width: 100%;
  }

  input {
    margin: 0 !important;
  }

  .form-container {
    width: 80%;
    margin: 0 auto;
  }

  ._form-thank-you {
    color: var(--light);
  }

  ._field-email {
    width: 90%;
  }

  ._form-content {
    display: flex;
  }

  @media only screen and (max-width: 1050px) {
    max-width: 80%;
  }

  @media only screen and (max-width: 700px) {
    max-width: 100%;
    display: block;

    button {
      margin: 2rem auto 0;
      width: 80%;
    }
  }
`
