import styled from 'styled-components'

export const BannerContainer = styled.div`
  background: radial-gradient(
    circle at 50% -200%,
    var(--light4),
    var(--light4) 90%,
    var(--light) 90%
  );

  padding-top: 2rem;
`
